import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup, InputGroup, Button, Intent, Dialog, Classes, Checkbox, Card, TextArea} from '@blueprintjs/core';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {toast} from "../../../store/toaster/actions";
import {toggleSubsistenceRateEditor, insertSubsistenceRate, updateSubsistenceRate, deleteSubsistenceRate} from "./store/actions";

import SlidingPanel from '../../../components/slidingpanel';
import {Box, Flex} from "@rebass/grid";
import Icon from "../../../components/icon";
import Select from "react-select";

const StyledEditor = styled(SlidingPanel)`
  .bp3-card {
    margin: 0 5px 16px 0;
    
    h4 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
`;


class SubsistenceRateEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: {
        name: '',
        country_id: null,
        local_currency_id: null,
        hours_5: '',
        hours_10: '',
        overnight_2: '',
        overnight: '',
        currency_2_id: null,
        admin_group_id: null,
        payroll_currency_id: null,
        additional_accommodation: 0,
        additional_accommodation_description: null,
      },
      values: {
        country_id: null,
        local_currency_id: null,
        payroll_currency_id: null,
        admin_group_id: null,
        currency_2_id: null,
        additional_accommodation: {label: 'No', value: 0},
      },
      options: [
        {label: 'Yes', value: 1},
        {label: 'No', value: 0},
      ],
      country_options: [],
      currencies_options: [],
      admingroup_options: [],
      deleteDialog: false,
      errors: {},
      sent: false,
    };
  }

  formatCountries(data) {
    const countries = [];
    data.map(record => {
      countries.push({label: record.name, value: record.id});
    })
    return countries;
  }

  formatCurrency(data) {
    const currencies = [];
    data.map(record => {
      currencies.push({label: record.name, value: record.id, code: record.code});
    })
    return currencies;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const countries = this.formatCountries(this.props.countries);
    const currencies = this.formatCurrency(this.props.currencies);
    const admingroup = this.formatCountries(this.props.admingroup);
    if(nextProps.record && nextProps.record.id) {
      const selectedCountry = countries.find(country => country.value === nextProps.record.country_id && nextProps.record.country_id);
      const selectedCurrency = currencies.find(currency => currency.value === nextProps.record.local_currency_id && nextProps.record.local_currency_id);
      const selectedpayroll = currencies.find(currency => currency.value === nextProps.record.payroll_currency_id && nextProps.record.payroll_currency_id);
      const selectedadmingroup = admingroup.find(admingroup => admingroup.value === nextProps.record.admin_group_id && nextProps.record.admin_group_id);
      const selected2ndCurrency = currencies.find(currency => currency.value === nextProps.record.currency_2_id && nextProps.record.currency_2_id);
      this.setState({
        record: {
          id: nextProps.record.id ? nextProps.record.id : '',
          name: nextProps.record.name ? nextProps.record.name : '',
          currency_code: nextProps.record.currency_code ? nextProps.record.currency_code : '',
          country_id: nextProps.record.country_id ? nextProps.record.country_id : '',
          local_currency_id: nextProps.record.local_currency_id ? nextProps.record.local_currency_id : '',
          payroll_currency_id: nextProps.record.payroll_currency_id ? nextProps.record.payroll_currency_id : '',
          admin_group_id: nextProps.record.admin_group_id ? nextProps.record.admin_group_id : '',
          currency_2_id: nextProps.record.currency_2_id ? nextProps.record.currency_2_id : '',
          hours_5: nextProps.record.hours_5 ? nextProps.record.hours_5 : '',
          hours_10: nextProps.record.hours_10 ? nextProps.record.hours_10 : '',
          overnight_2: nextProps.record.overnight_2 ? nextProps.record.overnight_2 : '',
          overnight: nextProps.record.overnight ? nextProps.record.overnight : '',
          additional_accommodation: nextProps.record.additional_accommodation ? nextProps.record.additional_accommodation : 0,
          additional_accommodation_description: nextProps.record.additional_accommodation_description ? nextProps.record.additional_accommodation_description : '',
        },
        country_options: countries,
        currencies_options: currencies,
        admingroup_options: admingroup,
        values: {
          country_id: selectedCountry,
          local_currency_id: selectedCurrency,
          payroll_currency_id: selectedpayroll,
          admin_group_id: selectedadmingroup,
          currency_2_id: selected2ndCurrency,
          additional_accommodation: nextProps.record.additional_accommodation === 0 ? {value: false, label: 'No'} : nextProps.record.additional_accommodation === 1 ? {value: true, label: 'Yes'} : null,
        }
      });
    } else {
      this.setState({
        country_options: countries,
        currencies_options: currencies,
        admingroup_options: admingroup,
      });

    }
  }


  handleDelete()
  {
    this.setState({deleteDialog: false});
    this.props.deleteSubsistenceRate(this.props.match.params._id, this.props.record.id, () => {
      this.props.toast({message: 'SubsistenceRate was successfully deleted', intent: 'SUCCESS'});
      this.props.toggleSubsistenceRateEditor();
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      const { insertSubsistenceRate, updateSubsistenceRate, toggleSubsistenceRateEditor, toast, record } = this.props;
      const new_record = this.state.record;
      const data = {
        name: new_record.name,
        currency_code: new_record.local_currency_id && new_record.local_currency_id.code,
        country_id: new_record.country_id && new_record.country_id.value,
        local_currency_id: new_record.local_currency_id && new_record.local_currency_id.value,
        hours_5: new_record.hours_5,
        hours_10: new_record.hours_10,
        overnight_2: new_record.overnight_2,
        overnight: new_record.overnight,
        currency_2_id: new_record.currency_2_id && new_record.currency_2_id.value,
        admin_group_id: new_record.admin_group_id && new_record.admin_group_id.value,
        payroll_currency_id: new_record.payroll_currency_id && new_record.payroll_currency_id.value,
        additional_accommodation: new_record.additional_accommodation && new_record.additional_accommodation.value,
        additional_accommodation_description: new_record.additional_accommodation_description,
      }
      if (record.id){
        updateSubsistenceRate(record.company_id, record.id, data, status => {
          if (status === 'saved'){
            toast({message: 'SubsistenceRate was successfully updated', intent: 'SUCCESS'});
            toggleSubsistenceRateEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        insertSubsistenceRate(this.props.match.params._id, data, status => {
          if (status === 'saved'){
            toast({message: 'SubsistenceRate was successfully added', intent: 'SUCCESS'});
            toggleSubsistenceRateEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['name']){
      formIsValid = false;
      errors['name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleNotificationChange(type, value) {
    let val = null;
    if (!value) {
      val = null;
    } else if (value.hasOwnProperty('value') && value.value === false){
      val = false;
    } else {
      val = true;
    }
    this.setState({
      ...this.state,
      record: {
        ...this.state.record,
        [type]: value
      },
      values: {
        ...this.state.values,
        [type]: value,
      }
    },() => {
      if (this.state.sent){
        this.handleValidation();
      }
    });
  }

  render() {
    const {isEditorActive, toggleSubsistenceRateEditor} = this.props;
    const {record, values} = this.state;
    return (
      <StyledEditor isOpen={isEditorActive} width="520px" title="Subsistence Rate" onRequestClose={() => toggleSubsistenceRateEditor()}>
        <React.Fragment>
          <form onSubmit={(e) => this.handleSave(e)}>
            <FormGroup
              intent={this.state.errors.name ? Intent.DANGER : Intent.SUCCESS}
              label="Name"
              labelFor="name"
              labelInfo="*"
              helperText={this.state.errors.name && this.state.errors.name}
            >
              <InputGroup
                intent={this.state.errors.name ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="name"
                id="name"
                large
                placeholder="Name"
                defaultValue={record.name}
              />
            </FormGroup>            
            <FormGroup
              intent={this.state.errors.country_id ? Intent.DANGER : Intent.SUCCESS}
              label="Country"
              labelFor="country_id"
              labelInfo="*"
              helperText={this.state.errors.country_id && this.state.errors.country_id}
            >
              <Select
                isClearable
                placeholder="Not Set"
                id="country_id"
                options={this.state.country_options}
                name="country_id"
                onChange={option => this.handleNotificationChange('country_id', option)}
                value={values.country_id}
              />
            </FormGroup>            
            <Flex flexWrap={'wrap'}>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.hours_5 ? Intent.DANGER : Intent.SUCCESS}
                  label="Less Than 5 Hours"
                  labelFor="hours_5"
                  helperText={this.state.errors.hours_5 && this.state.errors.hours_5}
                >
                  <InputGroup
                    intent={this.state.errors.hours_5 ? Intent.DANGER : Intent.NONE}
                    onChange={(e) => this.handleChange(e)}
                    name="hours_5"
                    id="hours_5"
                    large
                    placeholder="Less Than 5 Hours"
                    value={record.hours_5}
                  />
                </FormGroup>
              </Box>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.hours_10 ? Intent.DANGER : Intent.SUCCESS}
                  label="5 - 10 Hours"
                  labelFor="hours_10"
                  helperText={this.state.errors.hours_10 && this.state.errors.hours_10}
                >
                  <InputGroup
                    intent={this.state.errors.hours_10 ? Intent.DANGER : Intent.NONE}
                    onChange={(e) => this.handleChange(e)}
                    name="hours_10"
                    id="hours_10"
                    large
                    placeholder="5 - 10 Hours"
                    value={record.hours_10}
                  />
                </FormGroup>
              </Box>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.overnight_2 ? Intent.DANGER : Intent.SUCCESS}
                  label="10 - 24 Hours"
                  labelFor="overnight_2"
                  helperText={this.state.errors.overnight_2 && this.state.errors.overnight_2}
                >
                  <InputGroup
                    intent={this.state.errors.overnight_2 ? Intent.DANGER : Intent.NONE}
                    onChange={(e) => this.handleChange(e)}
                    name="overnight_2"
                    id="overnight_2"
                    large
                    placeholder="10 - 24 Hours"
                    value={record.overnight_2}
                  />
                </FormGroup>
              </Box>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.overnight ? Intent.DANGER : Intent.SUCCESS}
                  label="Overnight"
                  labelFor="overnight"
                  helperText={this.state.errors.overnight && this.state.errors.overnight}
                >
                  <InputGroup
                    intent={this.state.errors.overnight ? Intent.DANGER : Intent.NONE}
                    onChange={(e) => this.handleChange(e)}
                    name="overnight"
                    id="overnight"
                    large
                    placeholder="Overnight"
                    value={record.overnight}
                  />
                </FormGroup>
              </Box>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.local_currency_id ? Intent.DANGER : Intent.SUCCESS}
                  label="Currency"
                  labelFor="local_currency_id"
                  labelInfo="*"
                  helperText={this.state.errors.local_currency_id && this.state.errors.local_currency_id}
                >
                  <Select
                    isClearable
                    placeholder="Not Set"
                    id="local_currency_id"
                    options={this.state.currencies_options}
                    name="local_currency_id"
                    onChange={option => this.handleNotificationChange('local_currency_id', option)}
                    value={values.local_currency_id}
                  />
                </FormGroup>  
              </Box>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.currency_2_id ? Intent.DANGER : Intent.SUCCESS}
                  label="2nd Currency"
                  labelFor="currency_2_id"
                  helperText={this.state.errors.currency_2_id && this.state.errors.currency_2_id}
                >
                  <Select
                    isClearable
                    placeholder="Not Set"
                    id="currency_2_id"
                    options={this.state.currencies_options}
                    name="currency_2_id"
                    onChange={option => this.handleNotificationChange('currency_2_id', option)}
                    value={values.currency_2_id}
                  />
                </FormGroup> 
              </Box>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.payroll_currency_id ? Intent.DANGER : Intent.SUCCESS}
                  label="Payroll Currency"
                  labelFor="payroll_currency_id"
                  labelInfo="*"
                  helperText={this.state.errors.payroll_currency_id && this.state.errors.payroll_currency_id}
                >
                  <Select
                    isClearable
                    placeholder="Not Set"
                    id="payroll_currency_id"
                    options={this.state.currencies_options}
                    name="payroll_currency_id"
                    onChange={option => this.handleNotificationChange('payroll_currency_id', option)}
                    value={values.payroll_currency_id}
                  />
                </FormGroup> 
              </Box>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.admin_group_id ? Intent.DANGER : Intent.SUCCESS}
                  label="Admin Group"
                  labelFor="admin_group_id"
                  labelInfo="*"
                  helperText={this.state.errors.admin_group_id && this.state.errors.admin_group_id}
                >
                  <Select
                    isClearable
                    placeholder="Not Set"
                    id="admin_group_id"
                    options={this.state.admingroup_options}
                    name="admin_group_id"
                    onChange={option => this.handleNotificationChange('admin_group_id', option)}
                    value={values.admin_group_id}
                  />
                </FormGroup> 
              </Box>
            </Flex>
            <Card>
              <h4>Additional Accommodation</h4>
              <FormGroup
                label="Enable Additional Accommodation"
                labelFor="additional_accommodation"
              >
                <Select
                  isClearable
                  placeholder="Not Set"
                  id="additional_accommodation"
                  options={this.state.options}
                  name="additional_accommodation"
                  onChange={option => this.handleNotificationChange('additional_accommodation', option)}
                  value={values.additional_accommodation}
                  />
              </FormGroup>

              <FormGroup
                label="Additional Accommodation Description"
                labelFor="additional_accommodation_description"
                >
                <TextArea
                  disabled={values.additional_accommodation && values.additional_accommodation.value ? false : true}
                  style={{ width: '100%' }}
                  growVertically={false}
                  large={true}
                  id="additional_accommodation_description"
                  name="additional_accommodation_description"
                  onChange={(e) => this.handleChange(e)}
                  value={record.additional_accommodation_description}
                />
              </FormGroup>
            </Card>            
            <div className="button-bar">
              <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
              {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
            </div>
          </form>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Bank Account Delete"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      </StyledEditor>
    );
  }
}

function mapStateToProps(state){
  return {
    isEditorActive: state.settingsSubsistenceRate.isEditorActive,
    record: state.settingsSubsistenceRate.recordToEdit,
    currencies: state.settingsSubsistenceRate.details ? state.settingsSubsistenceRate.details.currencies : [],
    admingroup: state.settingsSubsistenceRate.details ? state.settingsSubsistenceRate.details.admingroup : [],
    countries: state.general.countries,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleSubsistenceRateEditor,
    updateSubsistenceRate,
    insertSubsistenceRate,
    deleteSubsistenceRate,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(SubsistenceRateEditor));
